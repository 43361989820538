// tailwind styles
import '@inkd/config/tailwind.css';

import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react'; // <-- Import SessionProvider
import { apolloClient, useMSWClient } from '@/libs';
import CardsAfterDarkContext from '../context/cardsAfterDark';
import FlagToggleContext from '@web/context/flagToggle';

export default function App({ Component, pageProps }: AppProps) {
  // If you need the MSW client
  useMSWClient();

  return (
    <SessionProvider session={pageProps.session}>
      <ApolloProvider client={apolloClient}>
        <FlagToggleContext>
          <CardsAfterDarkContext>
            <Component {...pageProps} />
          </CardsAfterDarkContext>
        </FlagToggleContext>
      </ApolloProvider>
    </SessionProvider>
  );
}
